import axios from 'axios'

export const API_URL = (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? 'http://localhost/atencion/api' : '/api';
export const PDF_URL = (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? 'http://localhost/atencion/formatos' : '/formatos';
export const ARCHIVOS_URL = (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? 'http://localhost/atencion/archivos' : '/archivos';
const api = {
    iniciarSesion( datos) {
        return axios.post(`${API_URL}/sesion.php`, datos);
    },
    restaurarSesion() {
        return axios.get(`${API_URL}/sesion.php`);
    },
    //////////////////////////////////////////////
    graficaAreas(busqueda) {
        return axios.get(`${API_URL}/gareas.php`, { params: busqueda });
    },
    graficaTipoSolicitud(busqueda) {
        return axios.get(`${API_URL}/gtipos.php`, { params: busqueda });
    },
    //////////////////////////////////////////////
    //////////////////////////////////////////////
    reporteAreas(busqueda) {
        return axios.get(`${API_URL}/reportes.php?tipo=areas`, { params: busqueda });
    },
    reporteEstatus(busqueda) {
        return axios.get(`${API_URL}/reportes.php?tipo=estatus`, { params: busqueda });
    },
    reporteTipos(busqueda) {
        return axios.get(`${API_URL}/reportes.php?tipo=tipos`, { params: busqueda });
    },
    //////////////////////////////////////////////
    buscarSolicitudes(busqueda) {
        return axios.get(`${API_URL}/solicitud.php`, {params: busqueda});
    },
    listarSolicitudes(tipoSolicitud) {
        return axios.get(`${API_URL}/solicitud.php?tipo=${tipoSolicitud}`);
    },
    listarAvances(idSolicitud) {
        return axios.get(`${API_URL}/avances.php?id=${idSolicitud}`);
    },
    listarInstrucciones() {
        return axios.get(`${API_URL}/instrucciones.php`);
    },
    abrirSolicitud(id) {
        return axios.get(`${API_URL}/solicitud.php?id=${id}`);
    },
    guardarAvance(avance, evento = null) {
        let adicional = {}
        if (evento != null) {
            adicional.onUploadProgress = evento;
        }
        return axios.post(`${API_URL}/avances.php`, avance, adicional);
    },
    guardarSolicitud(solicitud, evento = null) {
        let adicional = {}
        if (evento != null) {
            adicional.onUploadProgress = evento;
        }
        return axios.post(`${API_URL}/solicitud.php`, solicitud, adicional);
    },
    //////////////////////////////////////////////
    listarAreas() {
        return axios.get(`${API_URL}/areas.php`);
    },
    listarAreasFederales() {
        return axios.get(`${API_URL}/areas.php?tipo=federal`);
    },
    listarAreasEstatales() {
        return axios.get(`${API_URL}/areas.php?tipo=estatal`);
    },
    guardarArea(datos) {
        return axios.post(`${API_URL}/areas.php`, datos);
    },
    eliminarArea(id) {
        return axios.delete(`${API_URL}/areas.php`, { data: { 'id': id } });
    },
    //////////////////////////////////////////////
    listarTiposUsuario() {
        return axios.get(`${API_URL}/tiposusuario.php`);
    },
    listarUsuarios() {
        return axios.get(`${API_URL}/usuarios.php`);
    },
    guardarUsuario(datos) {
        return axios.post(`${API_URL}/usuarios.php`, datos);
    },
    eliminarUsuario(id) {
        return axios.delete(`${API_URL}/usuarios.php`, { data: { 'id': id } });
    },
    //////////////////////////////////////////////
    listarEstatusSolicitud() {
        return axios.get(`${API_URL}/estatus.php`);
    },
    guardarEstatusSolicitud(datos) {
        return axios.post(`${API_URL}/estatus.php`, datos);
    },
    eliminarEstatusSolicitud(id) {
        return axios.delete(`${API_URL}/estatus.php`, { data: { 'id': id } });
    },
    //////////////////////////////////////////////
    listarPrioridades() {
        return axios.get(`${API_URL}/prioridad.php`);
    },
    guardarPrioridad(datos) {
        return axios.post(`${API_URL}/prioridad.php`, datos);
    },
    eliminarPrioridad(id) {
        return axios.delete(`${API_URL}/prioridad.php`, { data: { 'id': id } });
    },
    //////////////////////////////////////////////
    eliminarGradoEstudio(id) {
        return axios.delete(`${API_URL}/gradoestudios.php`, { data: { 'id': id } });
    },
    listarGradoEstudio() {
        return axios.get(`${API_URL}/gradoestudios.php`);
    },
    guardarEstudio(datos) {
        return axios.post(`${API_URL}/gradoestudios.php`, datos);
    },
    //////////////////////////////////////////////
    //////////////////////////////////////////////
    eliminarTipoSolicitud(id) {
        return axios.delete(`${API_URL}/tiposolicitudes.php`, { data: { 'id': id } });
    },
    listarTipoSolicitud() {
        return axios.get(`${API_URL}/tiposolicitudes.php`);
    },
    guardarTipoSolicitud(datos) {
        return axios.post(`${API_URL}/tiposolicitudes.php`, datos);
    },
    //////////////////////////////////////////////
    buscaCp(cp) {
        return axios.get(`${API_URL}/cp.php`, {
            params: {
                buscar: cp
            }
        });
    },
    buscaCurp(curp) {
        return axios.get(`${API_URL}/personas.php?curp=${curp}`);
    },
    buscaTelefono(tel) {
        return axios.get(`${API_URL}/personas.php?tel=${tel}`);
    },
    listarLocalidades(municipio) {
        return axios.get(`${API_URL}/localidades.php?id_municipio=${municipio}`);
    },
    listarMunicipios(estado) {
        return axios.get(`${API_URL}/municipios.php?id_estado=${estado}`);
    },
    listarEstados() {
        return axios.get(`${API_URL}/estados.php`);
    },
}
export default api;