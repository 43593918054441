import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Inicio from '../views/Inicio.vue'
import Solicitud from '../views/Solicitud.vue'
import Solicitud2 from '../views/Solicitud2.vue'
import SolicitudCompleta from '../views/SolicitudCompleta.vue'
import SolicitudFederal from '../views/SolicitudFederal.vue'
import SolicitudD from '../views/SolicitudD.vue'
import Solicitudes from '../views/Solicitudes.vue'
import Estatus from '../views/Estatus.vue'
import Acceso from '../views/Acceso.vue'
import Areas from '../views/Areas.vue'
import Bitacora from '../views/Bitacora.vue'
import PrioridadSolicitud from '../views/PrioridadSolicitud.vue'
import TipoSolicitud from '../views/TipoSolicitud.vue'
import EstatusSolicitud from '../views/EstatusSolicitud.vue'
import Usuarios from '../views/Usuarios.vue'
import RAreas from '../views/RAreas.vue'
import REstatus from '../views/REstatus.vue'
import RTipos from '../views/RTipos.vue'
import GAtencionArea from '../views/GAtencionArea.vue'
import GSolicitud from '../views/GSolicitud.vue'
import Salir from '../views/Salir.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/solicitud/:id',
    name: 'SolicitudD',
    component: SolicitudD
  },
  {
    path: '/solicitudd',
    name: 'SolicitudD2',
    component: SolicitudD
  },
  {
    path: '/solicitud2',
    name: 'Solicitud2',
    component: Solicitud2,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        sessionStorage.setItem('redirectPath', to.path);
        next('/acceso')
      }
    }
  },
  {
    path: '/solicitud',
    name: 'Solicitud',
    component: Solicitud
  },
  {
    path: '/solicitudcompleta',
    name: 'SolicitudCompleta',
    component: SolicitudCompleta,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        sessionStorage.setItem('redirectPath', to.path);
        next('/acceso')
      }
    }
  },
  {
    path: '/solicitudfederal',
    name: 'SolicitudFederal',
    component: SolicitudFederal,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        sessionStorage.setItem('redirectPath', to.path);
        next('/acceso')
      }
    }
  },
  {
    path: '/estatus',
    name: 'Estatus',
    component: Estatus
  },
  {
    path: '/acceso',
    name: 'Acceso',
    component: Acceso
  },
  {
    path: '/solicitudes',
    name: 'Solicitudes',
    component: Solicitudes,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/solicitudes/:id',
    name: 'SolicitudesId',
    component: Solicitudes,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        sessionStorage.setItem('redirectPath', to.path);
        next('/acceso')
      }
    }
  },
  {
    path: '/areas',
    name: 'Areas',
    component: Areas,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/tiposolicitud',
    name: 'TipoSolicitud',
    component: TipoSolicitud,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/prioridadsolicitud',
    name: 'PrioridadSolicitud',
    component: PrioridadSolicitud,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/estatusolicitud',
    name: 'EstatusSolicitud',
    component: EstatusSolicitud,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: Usuarios,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/rareas',
    name: 'RAreas',
    component: RAreas,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/rtipos',
    name: 'RTipos',
    component: RTipos,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/restatus',
    name: 'REstatus',
    component: REstatus,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    },
  },
  {
    path: '/gatencionarea',
    name: 'GAtencionArea',
    component: GAtencionArea,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }

  },
  {
    path: '/gsolicitud',
    name: 'GSolicitud',
    component: GSolicitud,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }

  },
  {
    path: '/salir',
    name: 'Salir',
    component: Salir,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }

  },
  {
    path: '/bitacora',
    name: 'Bitacora',
    component: Bitacora,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
